body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.row{
  --bs-gutter-x: 0 !important;
}

footer, p{
  font-size: 18px;
}

.adminCard{
  border: 2px black solid;
  border-radius: 10px;
  height: 200px !important;
  width: 200px !important;
  padding-top: .5rem;
  /* display: flex;
  justify-content: center; */
}

.adminCol{
  display: flex;
  justify-content: center;
}

.adminImg{
  height: 125px;
  width: 125px !important;
}

.appImg{
  width: 100px;
  border-radius: 15px;
}

.appImgCol{
  border-right: black solid 2px;
  
}

#hiddenButton{
  display: none;
}

#hiddenElement{
  width: 25px;
  height: 25px;
}

/* body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
} */

#hero {
  background: #000;
  color: #fff;
  padding: 50px 20px;
  text-align: center;
}

.cta-button {
  background: #007bff;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

#about, #services, #portfolio, #contact, #cta {
  padding: 50px 20px;
  text-align: center;
}

.service, .portfolio-item {
  margin: 20px 0;
}

.social-links a {
  margin: 0 10px;
  text-decoration: none;
  color: #007bff;
}

.logo{
  max-width: 275px;
  align-self: center;
  justify-self: center;
}

.card-body, li{
  font-size: 18px;
}

.productCard{
  max-height: 475px !important;
  width: 324px !important
}